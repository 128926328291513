import React, { useContext } from 'react'
import { Modal } from 'react-bootstrap'
import { useForm } from 'react-hook-form';
import { Context } from '../..';
import { observer } from 'mobx-react-lite';
import UserServices from '../../services/general/userServices';
import { toast } from 'react-toastify';

const ClearBasketModal = observer(({show, setShow}) => {
    const handleClose = () => setShow(false);

    const {app, user} = useContext(Context);

    const {handleSubmit, register, formState: {errors}} = useForm();

    const CustomSubmit = async () => {
        const res = await UserServices.cart.clear(user.uid);

        if(res.statusCode === 200){
            user.setBasketChange(Math.random().toString(16));
            toast.success(res.message)
            setShow(false)
        }else{
            toast.error(res.message)
        }
    };

  return (
    <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
            <form className='form-modal' onSubmit={handleSubmit(CustomSubmit)}>
                <h2 className='mb-4'>{app.localizationsItems?.clear_basket_content}</h2>
                <div className='row justify-content-end'>
                    <div className="col-auto">
                        <div className='btn btn-light shopping-button text-dark' onClick={() => setShow(false)}>{app.localizationsItems?.cancel}</div>
                    </div>
                    <div className="col-auto">
                        <button className='btn btn-animation fw-bold ms-auto'>{app.localizationsItems?.delete}</button>
                    </div>
                </div>
            </form>
        </Modal.Body>
    </Modal>
  )
})

export default ClearBasketModal