import React, { useContext, useEffect, useState } from 'react'
import Utils from '../../services/utils';
import { Context } from '../..';
import img from "../../images/placeholder.png"
import { observer } from 'mobx-react-lite';
import Nouislider from 'nouislider-react';
import UserServices from '../../services/general/userServices';
import { NavLink } from 'react-router-dom';

const SummaryBoxCheckout = observer(({usedBonus, setUsedBonus, delivery, address, deliveryZones}) => {
    const {app, user} = useContext(Context);
    const [total, setTotal] = useState(0);
    const [subTotal, setSubTotal] = useState(0);
    const [bonuses, setBonuses] = useState();
    const [discount, setDiscount] = useState();
    const [balance, setBalance] = useState(0);

    const [deliveryPrice, setDeliveryPrice] = useState();
    const [maxDeliveryPrice, setMaxDeliveryPrice] = useState()

    const [isTimeToDelivery, setIsTimeToDelivery] = useState();
    const [timeIsMore, setTimeIsMore] = useState();

    const userProfile = async () => {
        const res = await UserServices.profile.index();

        if(res.statusCode === 200){
            setBalance(res.content.balance);
        }
    }

    useEffect(() => {
        if(app.settings){
            const time = app.settings.find(it => it.type === "time_until_delivery")?.value[0];
            
            if(time){
                setIsTimeToDelivery(true);

                const currentTime = new Date();
                const hours = currentTime.getHours();
                const minutes = currentTime.getMinutes();
    
                const currentTimeString = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
    
                if (currentTimeString > time) {
                    setTimeIsMore(true)
                }else{
                    setTimeIsMore(null)
                }

            }else{
                setIsTimeToDelivery(null);
            }
        }
    }, [app.settings])

    useEffect(() => {
        if(user.token){
            userProfile();
        }
    }, [])

    useEffect(() => {
        if (!user.cartProducts) return;
    
        let temp = 0;
        let temp2 = 0;
        let temp3 = 0;
    
        user.cartProducts.forEach((product) => {
            const price = Number(product?.price);
            const count = Number(product.count);
            const oldPrice = Number(product?.old_price ? product.old_price : product?.price);
    
            temp += price * count;
            temp2 += oldPrice * count;
    
            if (product.bonus?.type === 1) {
                temp3 += Math.floor((price * Number(product.bonus.number)) / 100) * count;
            } else if (product.bonus) {
                temp3 += Math.floor(Number(product.bonus.number) * count);
            }
        });
    
        setTotal(temp);
        setSubTotal(temp2);
    
        if (temp3 && temp3 > 0) {
            setBonuses(temp3);
        } else {
            setBonuses(null);
        }
    
        setDiscount(temp2 !== temp ? Number(temp2) - Number(temp) : null);
    }, [user.cartProducts]);

    useEffect(() => {
        if(address?.json_address && deliveryZones){
                let values = null;
                for(let i = 0; i<deliveryZones.length; i++){
                    const result = Utils.isMarkerInsidePolygon([address.json_address.lat, address.json_address.lng], deliveryZones[i].zones);
                    if(result){
                        values = deliveryZones[i].value
                    }
                }

                if(values?.length >= 1){
                    for(let i = 0; i<values.length; i++){
                        if(total > values[i].price_from && (total < values[i].price_to || (values[i].price_to === 0 || !values[i].price_to))){
                            setDeliveryPrice(values[i].delivery_price)
                        }
                    }
                    const lastItem = values[values.length-1];
                    lastItem.delivery_price <= 0 && setMaxDeliveryPrice(lastItem.price_from)
                }else{
                    setDeliveryPrice(null)
                    setMaxDeliveryPrice(null)
                }
        }
    }, [address, deliveryZones, total])

    var format = {
        to: function(value) {
            return parseInt(value);
        },
        from: function (value) {
            return parseInt(value);
        }
    };

    const handleUpdate = (e) => {
        setUsedBonus(e[0]);
    }

    const handleChange = (e) => {
        let temp = Number(e[0]);
        setTotal(Number(total) - temp);
        let temp3 = 0;
        for(let i =0; i< user.cartProducts.length; i++) {
            let summ = (Number(user.cartProducts[i].price) * Number(user.cartProducts[i].count));
            let procent = (summ * 100) / total;
            let dif = (temp * procent) / 100;
            if(user.cartProducts[i].bonus?.type == 1){
                temp3 += Math.floor((Number(summ - dif) * user.cartProducts[i].bonus.number)/100);
            }else if(user.cartProducts[i].bonus){
                temp3 += Math.floor((Number(user.cartProducts[i].bonus?.number) * Number(user.cartProducts[i]?.count)))
            }
        }
        setBonuses(Math.floor(temp3))
    }
    
    const calculateTotalWithDeliveryAndBonuses = () => {
        let totalWithDelivery = total;
    
        if (delivery?.slug !== "self" && deliveryPrice) {
            totalWithDelivery += Number(deliveryPrice);
        }
        return totalWithDelivery;
    };
    
  return (
    <div className="right-side-summery-box">
        <div className="summery-box-2">
            <div className="summery-header">
                <h3>{app.localizationsItems?.total_cart}</h3>
            </div>

            <ul className="summery-contain">
                {user?.cartProducts?.map((product, index) =>
                    <li key={index} className='li-present'>
                        <div className='li-flex'>
                            {product.bonus && 
                                <span className='cart-bonus'>{product.bonus.number}{product.bonus.type == 1 ? '%' : ''} 
                                    <svg width="7" height="10" viewBox="0 0 7 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M5.72394 2.18445V3.258H2.08998V9.99988H0.748047V2.18445H5.72394ZM1.68203 5.18501H3.64663C4.24066 5.18501 4.7488 5.28521 5.17106 5.48561C5.59333 5.68243 5.91539 5.95976 6.13726 6.31761C6.35912 6.67546 6.47006 7.09235 6.47006 7.56829C6.47006 7.92614 6.40743 8.25357 6.28219 8.55059C6.15694 8.8476 5.97265 9.10525 5.72931 9.32354C5.48597 9.53825 5.18896 9.70465 4.83826 9.82274C4.49115 9.94083 4.09394 9.99988 3.64663 9.99988H0.748047V2.18445H2.10072V8.9317H3.64663C3.98658 8.9317 4.26571 8.86907 4.48399 8.74383C4.70228 8.615 4.86331 8.44681 4.96709 8.23926C5.07445 8.03171 5.12812 7.80447 5.12812 7.55756C5.12812 7.3178 5.07445 7.09951 4.96709 6.90269C4.86331 6.70587 4.70228 6.54842 4.48399 6.43033C4.26571 6.31224 3.98658 6.2532 3.64663 6.2532H1.68203V5.18501Z" fill="white"/>
                                        <path d="M0.748047 0.00146484H5.7229V1.09912H0.748047V0.00146484Z" fill="white"/>
                                    </svg>
                                </span>
                            }
                            <img src={product.image} className="img-fluid blur-up lazyloaded checkout-image" alt=""
                                onError={({ currentTarget }) => {
                                    currentTarget.onerror = null; 
                                    currentTarget.src = img
                                }}
                            />
                            <NavLink to={`/${app.lang}/products/${product.slug}`}>{product.title} <span>X {product.count}</span></NavLink>
                            <h4 className="price">
                                <div>{Utils.numberWithSpaces(Number(product.count) * Number(product.price))} {app.localizationsItems?.currency}</div>
                                {product.old_price ? <div className='old-price'>{Utils.numberWithSpaces(Number(product.count) * Number(product.old_price))} {app.localizationsItems?.currency}</div> : <></>}
                            </h4>
                        </div>
                        {product.additional_product &&
                            <div className='additional-product'>
                                <img src={product.additional_product.preview_image} alt="" />
                                <NavLink to={`/${app.lang}/products/${product.additional_product.slug}`}>
                                    <div className='color'>{app.localizationsItems?.as_gift}</div>
                                    {product.additional_product.title}
                                </NavLink>
                            </div>
                        }
                    </li>
                )}
            </ul>
            {(user.token && balance > 0) && <div className='checkout-slider'>
                <h3>{app.localizationsItems?.use_bonuses}</h3>
                <div className='slider-labels'>
                    <span>0</span>
                    <span>{Number(balance)}</span>
                </div>
                <Nouislider
                    range={{ min: 0, max: Number(balance) }} 
                    start={0}
                    connect={'lower'}
                    tooltips = {true}
                    format = {format}
                    onUpdate = {e => handleUpdate(e)}
                    onChange={e => handleChange(e)}
                    step={10}
                />
            </div>}
            <ul className="summery-total">
                <li>
					<h4>{app.localizationsItems?.subtotal}</h4>
					<h4 className="price">{Utils.numberWithSpaces(subTotal)} {app.localizationsItems?.currency}</h4>
				</li>
                {deliveryPrice && <li>
                    <h4 >{app.localizationsItems?.delivery}</h4>
                    {(deliveryPrice <= 0 || delivery?.slug === "self") ? 
                        <h4 className="price">{app.localizationsItems?.free}</h4>
                        :
                        <h4 className="price">{Utils.numberWithSpaces(deliveryPrice)} {app.localizationsItems?.currency}</h4>
                    }
                </li>}
                {discount && <li>
                    <h4 >{app.localizationsItems?.discount}</h4>
                    <h4 className="price">- {Utils.numberWithSpaces(discount)} {app.localizationsItems?.currency}</h4>
                </li>}
                {usedBonus ? <li>
                    <h4>{app.localizationsItems?.bonuses_used}</h4>
                    <h4 className="price">- {Utils.numberWithSpaces(usedBonus)}</h4>
                </li> : <></>}
                <li className="list-total">
					<h4>{app.localizationsItems?.total}</h4>
					<h4 className="price">
                        <div>{Utils.numberWithSpaces(calculateTotalWithDeliveryAndBonuses())} {app.localizationsItems?.currency} </div>
                    </h4>
				</li>
                {bonuses ? <li>
                    <h4>{app.localizationsItems?.will_credited}</h4>
                    <h4 className="price">
                        <span className='price-bonus'>+ {Utils.numberWithSpaces(bonuses)} 
                            <svg width="7" height="10" viewBox="0 0 7 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.72394 2.18445V3.258H2.08998V9.99988H0.748047V2.18445H5.72394ZM1.68203 5.18501H3.64663C4.24066 5.18501 4.7488 5.28521 5.17106 5.48561C5.59333 5.68243 5.91539 5.95976 6.13726 6.31761C6.35912 6.67546 6.47006 7.09235 6.47006 7.56829C6.47006 7.92614 6.40743 8.25357 6.28219 8.55059C6.15694 8.8476 5.97265 9.10525 5.72931 9.32354C5.48597 9.53825 5.18896 9.70465 4.83826 9.82274C4.49115 9.94083 4.09394 9.99988 3.64663 9.99988H0.748047V2.18445H2.10072V8.9317H3.64663C3.98658 8.9317 4.26571 8.86907 4.48399 8.74383C4.70228 8.615 4.86331 8.44681 4.96709 8.23926C5.07445 8.03171 5.12812 7.80447 5.12812 7.55756C5.12812 7.3178 5.07445 7.09951 4.96709 6.90269C4.86331 6.70587 4.70228 6.54842 4.48399 6.43033C4.26571 6.31224 3.98658 6.2532 3.64663 6.2532H1.68203V5.18501Z" fill="white"/>
                                <path d="M0.748047 0.00146484H5.7229V1.09912H0.748047V0.00146484Z" fill="white"/>
                            </svg>
                        </span>
                    </h4>
                </li> : <></>}
            </ul>
        </div>
        {isTimeToDelivery && <div className="checkout-offer">
            <div className="offer-title">
                <div className="offer-name">
                        <span className="offer-ico">
                            <i className='fa fa-truck'></i>
                        </span>
                        {timeIsMore ? 
                            <h6>
                                {app.localizationsItems?.delivery_time1}
                            </h6>
                            :
                            <h6>
                                {app.localizationsItems?.delivery_time2}
                            </h6>
                        }
                    </div>
            </div>
        </div>}
        {(bonuses || (maxDeliveryPrice && Number(maxDeliveryPrice) > Number(total))) &&
            <div className="checkout-offer">
                <div className="offer-title">
                    {bonuses && <div className="offer-name">
                        <span className="offer-ico">
                            <i className='fa fa-circle-exclamation'></i>
                        </span>
                        <h6>
                            {app.localizationsItems?.accrual_bonuses}
                        </h6>
                    </div>}
                    {(maxDeliveryPrice > total) && <div className="offer-name">
                        <span className="offer-ico">
                            <i className='fa fa-truck'></i>
                        </span>
                        <h6>
                            {app.localizationsItems?.free_delivery} <span className='delivery-price'>{Utils.numberWithSpaces(Number(maxDeliveryPrice) - Number(total))} {app.localizationsItems?.currency}</span>
                        </h6>
                    </div>}
                </div>
            </div>
        }
        <button className="btn theme-bg-color text-white btn-md w-100 mt-4 fw-bold" disabled={Number(app.minimumOrderAmount) > total}>{app.localizationsItems?.checkout}</button>
        {Number(app.minimumOrderAmount) > total && <div className='red mt-6'>{app.localizationsItems?.minimal_sum_text} {Utils.numberWithSpaces(app.minimumOrderAmount)} {app.localizationsItems?.currency}</div>}
    </div>
  )
})

export default SummaryBoxCheckout