import React, { useContext, useEffect, useRef } from 'react'
import { useState } from 'react'
import { useForm } from 'react-hook-form';
import UserServices from '../../services/general/userServices';
import { Context } from '../..';
import { observer } from 'mobx-react-lite';
import { toast } from 'react-toastify';
import Utils from '../../services/utils';
import ProductApplicationModal from '../modals/ProductApplicationModal';
import DropdownProductPrice from './DropdownProductPrice';

const AddToBasket = observer(({item, setPrice, setOldPrice}) => {
    const {app, user} = useContext(Context);
    const [loading, setLoading] = useState();

    const {handleSubmit, register, setValue, getValues} = useForm();

    const [count, setCount] = useState(1);
    const [showModal, setShowModal] = useState();
    const [primaryPrice, setPrimaryPrice] = useState(item.price);

    const [selectedPrice, setSelectedPrice] = useState({value: "main", price: 0});

    useEffect(() => {
        if(item.traits){
            for(let i=0; i<item.traits.length; i++){
                let tempValue = JSON.stringify({value: item.traits[i].elements[0]?.name, price: item.traits[i].elements[0]?.price})
                item.traits[i].elements[0] && setValue(item.traits[i].title, tempValue)
            }
        }
    }, [item])

    const handleCalculate = (tempValues, atrPrice, altTitle) => {
        let tempPrice = 0;

        for(var key in tempValues){
            if(key !== altTitle){
                let tempValue = JSON.parse(tempValues[key]);
                tempPrice += tempValue?.price ? Number(tempValue.price) : 0
            }else{
                tempPrice += Number(atrPrice)
            }
        }

        let tempPrimaryPrice = tempPrice*count;

        if(selectedPrice){
            tempPrice += selectedPrice.price ? Number(selectedPrice.price) : 0;
        };

        tempPrice *= count;

        if(item.discount){
            setOldPrice(tempPrice)
            if(item.discount.type == 1){
                tempPrice -= (tempPrice * Number(item.discount.number))/100;
                tempPrimaryPrice -= (tempPrimaryPrice * Number(item.discount.number))/100;
            }else if(item.discount.type == 2){
                tempPrice -= Number(item.discount.number);
                tempPrimaryPrice -= Number(item.discount.number);
            }
        }

        setPrimaryPrice(tempPrimaryPrice);

        if(!tempPrice || tempPrice <= 0){
            setPrice(item.price);
            setOldPrice(item.old_price)
        }else{
            setPrice(tempPrice)
        }
    }

    useEffect(() => {
        const tempValues = getValues();
        handleCalculate(tempValues);
    }, [count, item])

    const addCount = () => {
        setCount(count+1)
    }

    const reduceCount = () => {
        if(count > 1){
            setCount(count-1)
        }
    }

    const changeCount = (e) => {
        setCount(e.target.value)
    }

    const handleRecalculation = (e, atrPrice, altTitle) => {
        const tempValues = getValues();
        handleCalculate(tempValues, atrPrice, altTitle);
    }

    const handleSelect = (card) => {
        setSelectedPrice(card)

        const tempValues = getValues();
        let tempPrice = 0;

        for(var key in tempValues){
            let tempValue = JSON.parse(tempValues[key]);
            tempPrice += tempValue?.price ? Number(tempValue.price) : 0
        }
        tempPrice += card.price ? Number(card.price) : 0;
        tempPrice *= count;

        if(item.discount){
            setOldPrice(tempPrice)
            if(item.discount.type == 1){
                tempPrice -= (tempPrice * Number(item.discount.number))/100;
            }else if(item.discount.type == 2){
                tempPrice -= Number(item.discount.number);
            }
        }
        
        setPrice(tempPrice)
    }

    const addToBasket = async(data) => {
        setLoading(true);
        let newData = {
            uid: user.uid ? user.uid : null,
            product_id: item.id,
            count: count,
            fields: [],
            price_config: selectedPrice
        }

        for(var key in data){
            let tempValue = JSON.parse(data[key]);
            newData.fields.push(
                {name: key, value: tempValue.value}
            )
        }

        const res = await UserServices.cart.addToCart(newData);

        if (res.statusCode === 200) {
            toast.success(app.localizationsItems?.add_product)
            if(!user.uid){
                res.content.uid && user.setUid(res.content.uid);
            }
            user.setBasketChange(Math.random().toString(16))
        }else{
            toast.error(res.message)
        }
        setLoading(false);
    }
    
    const handlePriceConfig = (tempPrice) => {
        let newPrice = (Number(tempPrice) * count);

        if(item.discount.type == 1){
            newPrice -= ((tempPrice * Number(item.discount.number))/100)*Number(count);
        }else if(item.discount.type == 2){
            newPrice -= Number(item.discount.number)*Number(count);
        }
        newPrice += Number(primaryPrice)

        return Utils.numberWithSpaces(newPrice)
    }

  return (
    <div className={loading ? 'isLoading isLoading-mini' : ''}>
        <form className='product-wrapper' onSubmit={handleSubmit(addToBasket)}>
            {item?.traits?.map((it, index) =>
                it.title &&
                    <div key={index}>
                        <div className='product-title'>{it.title}:</div>
                        <div className='product-flex'>
                            {it.elements?.map((el, index) =>
                                <label key={index} className='product-label'>
                                    <input  type="radio" {...register(it.title)} onChange={e => handleRecalculation(e, el.price, it.title)} required  value={JSON.stringify({value: el.name, price: el.price})}/>
                                    {el.image ?
                                        <span>
                                            <img src={el.image} alt="" />
                                        </span>
                                        :
                                        <span className='span-pad'>{el.name}</span>
                                    }
                                </label>
                            )}
                        </div>
                    </div>
            )}
            <div className='mb-3'></div>
            {item?.price_configs?.length >= 1 &&
                <>
                    <div className='configuration-price'>
                        <div className={selectedPrice.value === "main" ? 'configuration-price-item active' : 'configuration-price-item'} onClick={() => handleSelect({value: "main", price: 0})}>
                            <div>{app.localizationsItems?.primary_price}</div>
                            <div className='configuration-price-title'>{Utils.numberWithSpaces(primaryPrice)} {app.localizationsItems?.currency}</div>
                        </div>
                    </div>
                    {item?.price_configs?.map((card, index) =>
                        <div className='configuration-price' key={index}>
                            <div className={selectedPrice.value === card.value ? 'configuration-price-item active' : 'configuration-price-item'} onClick={() => handleSelect(card)}>
                                <div>{card.title}</div>
                                <div className='configuration-price-title'>{handlePriceConfig(card.price)} {app.localizationsItems?.currency}</div>
                                <DropdownProductPrice description={card.description}/>
                                {/*<Dropdown className='product-droupdown'>
                                    <Dropdown.Toggle>
                                        <i className='fa fa-info-circle'></i>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu >
                                        <div dangerouslySetInnerHTML={{__html: card.description}}></div>
                                    </Dropdown.Menu>
                    </Dropdown>*/}
                            </div>
                        </div>
                    )}
                </>
            }
            <div className="note-box product-packege">
                <div className="cart_qty qty-box product-qty">
                    <div className="input-group">
                        <button type="button" className="qty-left-minus" onClick={reduceCount}>
                            <i className="fa fa-minus" aria-hidden="true"></i>
                        </button>
                        <input className="form-control input-number qty-input" type="number"
                            value={count}
                            onChange={e => changeCount(e)}
                            required
                        />
                        <button type="button" className="qty-right-plus" onClick={(addCount)}>
                            <i className="fa fa-plus"></i>
                        </button>
                    </div>
                </div>
                {((item.shipment?.slug === "available" && (item.amount && item.amount > 0) || (item.shipment?.slug === "available" && !item.amount))) ?
                    <button className="btn btn-md theme-bg-color cart-button text-white w-100">{app.localizationsItems?.add_to_cart}</button>
                    :
                    <button className="btn btn-md theme-bg-color cart-button text-white w-100" disabled={!true}>{app.localizationsItems?.add_to_cart}</button>
                }
            </div>
        </form>
        {app.localizationsItems?.application_send && <button className='btn btn-md btn-border w-100 mt-3' onClick={() => setShowModal(true)}>{app.localizationsItems?.application_send}</button>}
        {showModal && <ProductApplicationModal show={showModal} setShow={setShowModal} title={item.title}/>}
    </div>
  )
})

export default AddToBasket